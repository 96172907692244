import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import {
  useVehicleDeliverySlots,
  useSetVehicleDeliveryTime,
} from "../../hooks/common";
import { useParams } from "react-router";
import CustomTimeModal from "./CustomTimer";
import Spinner from "../common/spinner/spinner";
import { getMonth } from "../../utils/getMonth";

const RecivingTimer = ({
  ticketId,
  ownerContact,
  attendentName,
  vehicleNumber,
  parkingTime,
  status,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { ticketId: ticketIdBackend } = useParams();
  const { data } = useVehicleDeliverySlots();
  const { mutate, isLoading } = useSetVehicleDeliveryTime();
  const [selectedTime, setSelectedTime] = useState("2");
  const handleSubmit = () => {
    mutate({
      ticketIdBackend,
      time: parseInt(selectedTime, 10),
    });
  };
  const handleSaveCustomTime = (time) => {
    setSelectedTime(time);
    handleSubmit();
  };
  const toggeleModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div class="container-fluid full-height">
      <div class="row row-height">
        {/* <!-- *********=========COL- STARTED =========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <!-- *********=========COL- CARDS =========*********  --> */}
        <div class="col-lg-7 side-card p-4 p-md-5">
          <h4 class="bold ">
            <span class="text-primary bold">Ticket</span> Details
          </h4>
          <div className="gray-bg details-section p-0 p-md-3 rounded-3 mb-5">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Parking Ticket </p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ownerContact}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcset="" />
                  <p className="m-0  w-100">Attendant Name</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                <p className="semibold properties ms-4 mb-0">
                  {`${getMonth(
                    String(new Date(parkingTime).getMonth())
                  )} ${String(new Date(parkingTime).getDate()).padStart(
                    2,
                    "0"
                  )}, ${new Date(parkingTime).getFullYear()} ${String(
                    new Date(parkingTime).getHours()
                  ).padStart(2, "0")}:${String(
                    new Date(parkingTime).getMinutes()
                  ).padStart(2, "0")}`}
                </p>
              </div>
            </div>
          </div>

          {status === "Waiting" ? (
            <h4 class="bold ">
              Owner <span class="text-primary bold">Requested </span> to Receive
              Vehicle
            </h4>
          ) : (
            <h4 class="bold ">
              Owner has <span class="text-primary bold"> Not Requested </span>{" "}
              to Receive Vehicle Yet
            </h4>
          )}
          {status === "Waiting" ? (
            <>
              <p>
                Please select a time slot, in that you are suitable to deliver
                the vehicle to the owner.
              </p>
              <div>
                <div class="d-flex gap-3 flex-wrap">
                  {data?.map((time, index) => {
                    if (selectedTime === time) {
                      return (
                        <button
                          key={index}
                          class="btn rounded-3 px-3 py-1 active medium btn-light-primary"
                          onClick={() => {
                            setSelectedTime(time);
                          }}
                        >
                          {`${time} mins`}
                        </button>
                      );
                    } else {
                      return (
                        <button
                          key={index}
                          class="btn rounded-3 px-3 py-1 medium btn-light-primary"
                          onClick={() => {
                            setSelectedTime(time);
                          }}
                        >
                          {`${time} mins`}
                        </button>
                      );
                    }
                  })}
                  {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
                  <button
                    class="btn rounded-3 px-3 py-1 medium btn-light-custom"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={toggeleModal}
                  >
                    custom
                  </button>
                </div>

                {/* <!-- *********========= Modal =========*********  --> */}
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content"></div>
                  </div>
                </div>
              </div>
              {/* <!-- Button trigger modal --> */}
              <div>
                <button
                  type="button"
                  class="btn rounded-4 px-5 mt-4 medium btn-primary submit-button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {isLoading ? <Spinner /> : "Submit"}
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <CustomTimeModal
        show={showModal}
        closeModal={toggeleModal}
        handleSave={handleSaveCustomTime}
      />
    </div>
  );
};
export default RecivingTimer;
