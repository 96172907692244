import React from "react";
import { OwnerMobile } from "../OwnerMobile";
import { useTicketData } from "../../../hooks/common";
import { useParams } from "react-router";
import TicketDetails from "../TicketDetails";
import VehicheStatus from "../VehicheStatus";
import { Feedback } from "../Feedback";
import useInterval from "../../common/useInterval";
import NotFoundPage from "../../common/NoTicketFound";

const VehicleOwnerContainer = () => {
  const { ticketId } = useParams();
  const { data, refetch } = useTicketData(ticketId);
  const interval = 5000; // 5 seconds

  // Use the useInterval hook to refetch the query at the specified interval
  useInterval(() => {
    refetch();
  }, interval);

  return (
    <>
      {!data ? <NotFoundPage /> : null}
      {data && !data?.ownerPhoneNumber ? (
        <OwnerMobile ticketId={data?.ticketId} />
      ) : null}
      {data?.parkingStatus &&
      !data?.deliveryTimeInMinutes &&
      data?.ownerPhoneNumber ? (
        <TicketDetails
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          isWaiting={data?.parkingStatus === "Waiting" ? true : false}
        />
      ) : null}
      {data?.deliveryTimeInMinutes && data?.parkingStatus !== "Delivered" ? (
        <VehicheStatus
          ticketId={data?.ticketId}
          ownerContact={data?.ownerPhoneNumber}
          attendentName={data?.attendentName}
          vehicleNumber={data?.vihicleNumber}
          parkingTime={data?.parkingTime}
          parkingStatus={data?.parkingStatus}
          time={parseInt(data?.deliveryTimeInMinutes)}
          startInterval={data?.deliveryStaringIntervel}
        />
      ) : null}
      {data?.parkingStatus === "Delivered" ? <Feedback /> : null}
    </>
  );
};

export default VehicleOwnerContainer;
