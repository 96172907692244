import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ErrorPage } from "./components/VehiceOwner/ErrorPage";
import VehicleOwnerContainer from "./components/VehiceOwner/VehicleOwnerContainer";
import AttendentContainer from "./components/Attendent/AttendentContainer/AttendentContainer";
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<ErrorPage />} />
        <Route path="/owner/:ticketId" element={<VehicleOwnerContainer />} />
        <Route path="/attendent/:ticketId" element={<AttendentContainer />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
