import React from "react";
import Modal from "react-bootstrap/Modal";
export const CustomTimeModal = ({ show, closeModal, handleSave }) => {
  const [customTime, setCustomTime] = React.useState(0);
  const handleSaveCustomTime = () => {
    handleSave(customTime);
    closeModal();
  };
  return (
    <>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={closeModal}
      >
        <div class="modal-header">
          <h1 class="modal-title fs-5 medium" id="exampleModalLabel">
            Enter Custom Time
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <Modal.Body>
          <div class="modal-body">
            <div class="">
              <label for="" class="form-label  small ">
                Custom Time
              </label>
              <p class="translate-mins float-end me-5">mins</p>
              <input
                type="number"
                min="0"
                class="form-control py-2 rounded-3"
                name=""
                id=""
                onChange={(e) => {
                  setCustomTime(e.target.value);
                }}
                placeholder="Please Enter"
              />
            </div>
          </div>
          <div class="modal-footer border-0">
            <button
              type="button"
              class="btn btn-close-modal px-3 py-1"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary px-3 py-1"
              onClick={handleSaveCustomTime}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomTimeModal;
