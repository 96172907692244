import React, { useState } from "react";
import inputmobilesvg from "../../assets/imgs/icons/input-mobile-svg.svg";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import VerticallyCenteredModal from "../common/Modal";
import Logo from "../../Logo";
import { useUpdateOwnerPhoneNumber } from "../../hooks/common";
import { useParams } from "react-router";
import Spinner from "../common/spinner/spinner";

export const OwnerMobile = ({ ticketId }) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [countryCode, setCountryCode] = useState("");

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  // const handleUpdatePhoneNumberSuccess = (statusCode) => {
  //   if (statusCode === 200) toggleModal();
  // };
  const { mutate, isLoading } = useUpdateOwnerPhoneNumber();
  const handlePhoneNumberChange = (event) => {
    const noSpecialChars = event.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    console.log("no special chars", noSpecialChars);
    setPhoneNumber(noSpecialChars);
  };
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };
  const handleSubmit = () => {
    let number = `${countryCode}${phoneNumber}`;
    mutate({ phoneNumber: number, ticketIdBackend });
  };
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        {/* <!-- *********=========COL SATRTED=========*********  --> */}
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <!-- *********=========COL CARDS=========*********  --> */}
        <div className="col-lg-7 side-card p-4 p-md-5">
          <h4 className="bold">
            <span className="text-primary bold">Ticket</span> Details
          </h4>
          <div className="gray-bg details-section p-0 p-md-3 rounded-3  mb-4 mb-md-5">
            <div className="vehicle-info">
              <div className="d-flex align-items-center ">
                <img className="me-2" src={ticket_svg} alt="" srcset="" />
                <p className="m-0 properties  w-100">Parking Ticket</p>
              </div>
              <p className="semibold  mb-0 ms-4">{ticketId}</p>
            </div>
          </div>
          <h4 className="bold">
            <span className="text-primary bold">Required</span> from your side
          </h4>
          <div className="col-sm-6">
            <label className=" small  mb-2">Phone Number</label>
            <div className="d-flex align-items-center  px-2 rounded-2 mb-2 svg-input">
              <img src={inputmobilesvg} alt="" srcset="" />
              <div className="d-flex">
                <select
                  style={{ maxWidth: "30%" }}
                  onChange={(e) => {
                    handleCountryCodeChange(e);
                  }}
                  className="p-2 border-0  text-dark outline-0 form-control"
                >
                  <option value="">+1 (USA)</option>
                  <option value="+91">+91 (India)</option>
                  <option value="+92">+92 (Pakistan)</option>
                  <option value="+44">+44 (United Kingdom)</option>
                  <option value="+61">+61 (Australia)</option>
                  <option value="+86">+86 (China)</option>
                  <option value="+33">+33 (France)</option>
                  <option value="+49">+49 (Germany)</option>
                  <option value="+91">+91 (India)</option>
                  <option value="+81">+81 (Japan)</option>
                  <option value="+52">+52 (Mexico)</option>
                  <option value="+7">+7 (Russia)</option>
                  <option value="+27">+27 (South Africa)</option>
                  <option value="+34">+34 (Spain)</option>
                  <option value="+54">+54 (Argentina)</option>
                  <option value="+55">+55 (Brazil)</option>
                  <option value="+1">+1 (Canada)</option>
                  <option value="+20">+20 (Egypt)</option>
                  <option value="+30">+30 (Greece)</option>
                  <option value="+91">+91 (Indonesia)</option>
                  <option value="+39">+39 (Italy)</option>
                  <option value="+81">+81 (Japan)</option>
                  <option value="+7">+7 (Kazakhstan)</option>
                  <option value="+60">+60 (Malaysia)</option>
                  <option value="+234">+234 (Nigeria)</option>
                  <option value="+63">+63 (Philippines)</option>
                  <option value="+48">+48 (Poland)</option>
                </select>
                <input
                  style={{ maxWidth: "70%" }}
                  type="text"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  placeholder="Please enter"
                  className="flex-grow-1 p-2 border-0 outline-0 text-dark form-control"
                />
              </div>
            </div>
            <p className=" small  mb-2">
              Input require by you to opt-in to receiving SMS notification your
              vehicle status to the number below:
            </p>
          </div>
          {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
          <button
            type="button"
            className="btn rounded-4 px-5 mt-4 medium btn-primary submit-button"
            data-bs-toggle="modal"
            data-bs-target="#successfullmessage"
            onClick={handleSubmit}
            disabled={!phoneNumber}
          >
            {isLoading ? <Spinner /> : "Submit"}
          </button>

          {/* <!-- *********========= Modal =========*********  --> */}

          <div
            className="modal fade"
            id="successfullmessage"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content text-center"></div>
            </div>
          </div>
        </div>
      </div>
      <VerticallyCenteredModal
        show={show}
        closeModal={toggleModal}
        title=" Phone Number Added Successfully...!"
        subtitle="  You will receive parking details on your phone number via SMS."
      />
    </div>
  );
};
