import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import contect_svg from "../../assets/imgs/icons/contect-svg.svg";
import car_svg from "../../assets/imgs/icons/car-svg.svg";
import calender_svg from "../../assets/imgs/icons/calender-svg.svg";
import { useRequestVehicle } from "../../hooks/common";
import { useParams } from "react-router";
import VerticallyCenteredModal from "../common/Modal";
import { getMonth } from "../../utils/getMonth";

const TicketDetails = ({
  ticketId,
  ownerContact,
  attendentName,
  vehicleNumber,
  parkingTime,
  isWaiting,
}) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const [disableRequest, setDisableRequest] = useState(false);

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };
  const handleRequestVehicleSuccess = (statusCode) => {
    if (statusCode === 200) setDisableRequest(true);
  };
  const { mutate } = useRequestVehicle(handleRequestVehicleSuccess);
  const handleSubmit = () => {
    mutate({ ticketIdBackend });
  };
  return (
    <div className="container-fluid full-height">
      <div className="row row-height">
        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-lg-7 side-card p-4 p-md-5 justify-content-center align-items-center">
          <div className="mb-4">
            <h4 className="bold ">
              Your <span className="text-primary bold">Ticket</span> Details
            </h4>
          </div>

          <div className="gray-bg details-section p-0 p-md-3 rounded-3 mb-5">
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Parking Ticket</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ownerContact}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={contect_svg} alt="" srcset="" />
                  <p className="m-0  w-100">Attendant Name</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{attendentName}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={car_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Vehicle License Plate #</p>
                </div>
                <p className="semibold properties ms-4 mb-0">{vehicleNumber}</p>
              </div>
              <div className="col-lg-4 col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={calender_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Parked at</p>
                </div>
                {parkingTime ? (
                  <p className="semibold properties ms-4 mb-0">
                    {`${getMonth(
                      String(new Date(parkingTime).getMonth())
                    )} ${String(new Date(parkingTime).getDate()).padStart(
                      2,
                      "0"
                    )}, ${new Date(parkingTime).getFullYear()} ${String(
                      new Date(parkingTime).getHours()
                    ).padStart(2, "0")}:${String(
                      new Date(parkingTime).getMinutes()
                    ).padStart(2, "0")}`}
                  </p>
                ) : (
                  <p className="semibold properties ms-4 mb-0">
                    {" "}
                    Not Parked Yet
                  </p>
                )}
              </div>
            </div>
          </div>
          <h4 className="bold">
            Send Vehicle <span className="text-primary bold">Request</span>
          </h4>
          {isWaiting ? (
            <p className="mobile-text">
              Your request to recieve the vehicle has been submitted. You will
              recieve your vehicle in a little bit, you attendent is on the way.
            </p>
          ) : (
            <p className="mobile-text">
              {attendentName
                ? ` Are you ready to leave ? Tap "REQUEST" to Send a Vehicle request to ${attendentName}.  `
                : ` Are You Ready to Leave ? Tap "REQUEST" to Send a Vehicle request. `}
            </p>
          )}
          {/* <!-- Button trigger modal --> */}

          <button
            type="button"
            className="btn rounded-4 px-5 mt-4 medium btn-primary submit-button"
            data-bs-toggle="modal"
            data-bs-target="#confirmodal"
            onClick={() => {
              toggleModal();
            }}
            disabled={!parkingTime || !vehicleNumber || isWaiting}
          >
            Request
          </button>

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="confirmodal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              {" "}
              {/* style=" width: 440.1px;height: 416.9px;"  */}
              <div className="modal-content text-center">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close small"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body px-5">
                  <div className="mb-4"></div>
                  <h6 className="semibold">
                    Are You Sure? You Want To <br /> Receive Your Vehicle
                  </h6>
                  <p className="">
                    By clicking "Yes" attendant will deliver you yours vehicle.
                  </p>
                  <div className="d-flex justify-content-center gap-3 mb-2">
                    <button
                      type="button"
                      className="btn medium btn-primary px-4 rounded-5"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-close-modal px-4 rounded-5"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VerticallyCenteredModal
            show={show}
            closeModal={toggleModal}
            type="error"
            actionName="confirm"
            handleAction={handleSubmit}
            subtitle="By Clicking Yes attendent will deliver you yours vehicle"
            title="Are You Ready to Go ? We Will Message Parking Attendant and Pull Your Car Out Front"
          />
        </div>
      </div>
    </div>
  );
};
export default TicketDetails;
