import React from "react";
import Logo from "../../Logo";
import error_img from "../../assets/imgs/error-page.png";

export const ErrorPage = () => {
  return (
    <div class="container-fluid full-height-error-page">
      <div class="p-4 p-md-5">
        <Logo />
      </div>
      <div class="row flex-wrap-reverse justify-content-center">
        <div class="col-lg-6 p-4 p-md-5">
          <div>
            <h4 class="semibold mt-lg-5">Thanks for parking with us !</h4>
            <h1
              style={{ fontSize: "medium" }}
              class="ExtraBold mb-lg-5 text-primary mb-0"
            >
              Download the for application{" "}
              <a style={{ color: "inherit" }} target="_blank" href="">
                here
              </a>{" "}
              for a completely paperless experience, avoid fees, and get access
              to promotion next time you park to this location
            </h1>
            {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p> */}
          </div>
        </div>
        <div class="col-lg-6 col-sm-8 mb-0 p-4 p-md-5">
          <img src={error_img} class="object-fit-cover h-100 w-100" alt="" />
        </div>
      </div>
    </div>
  );
};
