import {
  getTicketByID,
  updateOwnerPhoneNumber,
  updateVehicleNumber,
  requestVehicle,
  getVehicleDeliveryTimeSlots,
  setVehicleDeliveryTime,
  deliverVehicle,
  setFeedback,
  reparkVehicle,
  getEasternTime,
} from "../../services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useTicketData = (ticketId) => {
  return useQuery(["ticket"], () => getTicketByID(ticketId), {
    select: (data) => {
      return data.data;
    },
  });
};

export const useEasternTime = () => {
  return useQuery(["easternTime"], () => getEasternTime(), {
    select: (data) => {
      return data.data;
    },
  });
};

export const useDeliverVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (ticketId) => {
      return deliverVehicle(ticketId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};
export const useReparkVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (ticketId) => {
      return reparkVehicle(ticketId);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useSetFeedback = (handleSuccess) => {
  return useMutation(
    (data) => {
      return setFeedback(data.ticketId, data.stars, data.feedback);
    },
    {
      onSuccess: (data) => {
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useVehicleDeliverySlots = () => {
  return useQuery(
    ["vehicleDeliverySlots"],
    () => getVehicleDeliveryTimeSlots(),
    {
      select: (data) => {
        return data.data.split(",");
      },
    }
  );
};

export const useUpdateOwnerPhoneNumber = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return updateOwnerPhoneNumber(data.ticketIdBackend, data.phoneNumber);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
      },
    }
  );
};

export const useSetVehicleDeliveryTime = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return setVehicleDeliveryTime(data.ticketIdBackend, data.time);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};

export const useUpdateVehicleNumber = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return updateVehicleNumber(data.ticketIdBackend, data.vehicleNumber);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["ticket"]);
      },
    }
  );
};

export const useRequestVehicle = (handleSuccess) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return requestVehicle(data.ticketIdBackend);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["ticket"]);
        handleSuccess(data.statusCode);
      },
    }
  );
};
