import React from "react";
import Logo from "../../Logo";
import error_img from "../../assets/imgs/error-page.png";

const NotFoundPage = () => {
  return (
    <div class="container-fluid full-height-error-page">
      <div class="p-4 p-md-5">
        <Logo />
      </div>
      <div class="row flex-wrap-reverse justify-content-center">
        <div class="col-lg-6 p-4 p-md-5">
          <div>
            <h4 class="semibold mt-lg-5">Whoops...!</h4>
            <h1 class="ExtraBold mb-lg-5 text-primary mb-0">
              {" "}
              Ticket Not Found
            </h1>
            <p>
              Download the application{" "}
              <a style={{ color: "inherit" }} target="_blank" href="">
                here
              </a>{" "}
              for a completely paperless experience, avoid fees, and get access
              to promotion next time you park to this location
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-8 mb-0 p-4 p-md-5">
          <img src={error_img} class="object-fit-cover h-100 w-100" alt="" />
        </div>
      </div>
    </div>
  );
};
export default NotFoundPage;
