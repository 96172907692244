import { fetchWrapper } from "./restApi";
const API_URL_GET = process.env.REACT_APP_API_URL;

export const getTicketByID = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/TicketGetById?ticketId=${ticketId}`
  );
};

export const updateOwnerPhoneNumber = (ticketId, phoneNumber) => {
  return fetchWrapper("PUT", `${API_URL_GET}/UpdateOwnerPhoneNumber`, {
    id: ticketId,
    phoneNumber: phoneNumber,
  });
};

export const updateVehicleNumber = (ticketId, vehicleNumber) => {
  return fetchWrapper("PUT", `${API_URL_GET}/UpdateVehicleNumber`, {
    id: ticketId,
    vehicleNumber: vehicleNumber,
  });
};

export const requestVehicle = (ticketId) => {
  return fetchWrapper("PUT", `${API_URL_GET}/VehicleReceivingRequest`, {
    id: ticketId,
  });
};

export const getVehicleDeliveryTimeSlots = () => {
  return fetchWrapper("GET", `${API_URL_GET}/VehicleDeliveryTimeSlots`);
};

export const deliverVehicle = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/DeliverVehicle?ticketId=${ticketId}`
  );
};

export const setVehicleDeliveryTime = (ticketId, time) => {
  return fetchWrapper("PUT", `${API_URL_GET}/SetVehicleDeliveryTimeInternal`, {
    id: ticketId,
    deliveryTimeInMinutes: time,
  });
};

export const setFeedback = (ticketId, stars, feedback) => {
  return fetchWrapper("POST", `${API_URL_GET}/FeedbackCreate`, {
    parkingTicketId: ticketId,
    stars: stars,
    feedback: feedback,
  });
};

export const reparkVehicle = (ticketId) => {
  return fetchWrapper(
    "GET",
    `${API_URL_GET}/ReparkVehicle/?ticketId=${ticketId}`
  );
};

export const getEasternTime = () => {
  return fetchWrapper("GET", `https://ebay.owasoftltd.com/api/GetCurrentDateTime`);
};
