import React, { useState } from "react";
import Logo from "../../Logo";
import main_image from "../../assets/imgs/main-img.png";
import ticket_svg from "../../assets/imgs/icons/ticket-svg.svg";
import mobile_svg from "../../assets/imgs/icons/mobile-svg.svg";
import { useUpdateVehicleNumber } from "../../hooks/common";
import VerticallyCenteredModal from "../common/Modal";
import { useParams } from "react-router";
import Spinner from "../common/spinner/spinner";

export const VehicleVerification = ({ ownerContact, ticketId }) => {
  const { ticketId: ticketIdBackend } = useParams();
  const [show, setShow] = useState(false);
  const [vehicleNumber, setvehicleNumber] = useState(null);

  const toggleModal = () => {
    setShow((prevState) => {
      return !prevState;
    });
  };

  const { mutate, isLoading } = useUpdateVehicleNumber();
  const handleVehicleNumberChange = (event) => {
    setvehicleNumber(event.target.value);
  };
  const handleSubmit = () => {
    mutate({ vehicleNumber, ticketIdBackend });
  };
  return (
    <div class="container-fluid full-height">
      <div class="row row-height">
        {/* <!-- *********=========COL SATRTED=========*********  --> */}

        <div className="col-lg-5 p-4 p-md-5 ">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* <!-- *********=========COL- ENDED=========*********  --> */}
        {/* <!-- *********=========COL- CARD STARTED =========*********  --> */}
        <div class="col-lg-7 side-card p-4 p-md-5">
          <h4 class="bold  ">
            <span class="text-primary bold">Ticket</span> Details
          </h4>
          <div class="gray-bg details-section p-0 p-md-3 rounded-3 mb-5">
            <div class="row g-2">
              <div className=" col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={ticket_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Parking Ticket </p>
                </div>
                <p className="semibold properties ms-4 mb-0">{ticketId}</p>
              </div>
              <div className=" col-md-6 vehicle-info ">
                <div className="d-flex align-items-center">
                  <img className="me-2" src={mobile_svg} alt="" srcset="" />
                  <p className="m-0   w-100">Owner Contact</p>
                </div>
                {ownerContact?.length ? (
                  <p className="semibold properties ms-4 mb-0">
                    {ownerContact}
                  </p>
                ) : null}
                <p class="text-primary ms-4 mb-0">
                  {!ownerContact?.length ? "Owner has not entered yet" : null}
                </p>
              </div>
            </div>
          </div>
          <h4 class="bold">
            <span class="text-primary bold">Update</span> Vehicle License Plate
            Number :
          </h4>
          <div class="col-sm-6">
            <label class=" small  mb-2">Vehicle License Plate #</label>
            <div class="d-flex align-items-center  px-2 rounded-2 mb-2 svg-input">
              <img
                src="./assets/imgs/icons/input-mobile-svg.svg"
                alt=""
                srcset=""
              />
              <input
                type="text"
                placeholder="Please enter"
                onChange={handleVehicleNumberChange}
                class="w-100  text-dark p-2 border-0 outline-0 form-control"
              />
            </div>
          </div>
          {/* <!-- *********========= MODAL LONCH BUTTON =========*********  --> */}
          <div>
            <button
              type="button"
              class="btn rounded-4 px-5 mt-4 medium btn-primary submit-button"
              data-bs-toggle="modal"
              disabled={!vehicleNumber}
              data-bs-target="#exampleModal"
              onClick={handleSubmit}
            >
              {isLoading ? <Spinner /> : "Submit"}
            </button>
          </div>
          <VerticallyCenteredModal
            show={show}
            closeModal={toggleModal}
            type="error"
            title=" Parking number added successfully!"
          />
        </div>
      </div>
    </div>
  );
};
