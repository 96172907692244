import React, { useState } from "react";
import Logo from "../../Logo";
import { useSetFeedback } from "../../hooks/common";
import main_image from "../../assets/imgs/main-img.png";
import angery_face from "../../assets/imgs/angery-face.png";
import sad_face from "../../assets/imgs/sad-face.png";
import serious_face from "../../assets/imgs/serious-face.png";
import smiling_face from "../../assets/imgs/smiling-face.png";
import big_smile_face from "../../assets/imgs/big-smile-face.png";
import img1 from "../../assets/imgs/1.svg";
import img2 from "../../assets/imgs/2.svg";
import img3 from "../../assets/imgs/3.svg";
import img4 from "../../assets/imgs/4.svg";
import img5 from "../../assets/imgs/5.svg";
import { useParams, useNavigate } from "react-router";

export const Feedback = () => {
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const { mutate } = useSetFeedback();
  const [feedBack, setFeedBack] = useState("");
  const [stars, setStars] = useState(0);
  const handleSendFeedback = () => {
    mutate({
      ticketId,
      stars,
      feedback: feedBack,
    });
    navigate("/");
  };
  return (
    <div class="container-fluid full-height">
      <div class="row row-height">
        <div class="col-lg-5 p-4 p-md-5">
          <div className="row">
            <div className="">
              <Logo />
            </div>
            <div className="mt-lg-5 text-center text-md-start text-lg-center d-md-flex justify-content-between align-items-end d-lg-block">
              <div className="pb-md-5 pb-lg-0">
                <h4 className="semibold mt-3 ">Welcome to</h4>
                <h1 className="ExtraBold mb-lg-5 text-primary mb-0">
                  ValetXpress
                </h1>
              </div>
              <img
                src={main_image}
                className="car-image object-fit-cover mt-3 mb-0 mb-md-3"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="col-lg-7 side-card p-4 p-md-5">
          <h4 class="bold ">
            Share Us Your <span class="text-primary bold">Feedback</span>
          </h4>
          <div class=" rounded-3 mb-4">
            <div>
              <p class="semibold ">How do you rate our service?</p>
            </div>
            <div class="Feedback-emoji d-flex gap-3 gap-md-5 justify-content-center align-items-center ">
              <div
                onClick={() => {
                  setStars(1);
                }}
              >
                <img
                  className="pe-auto"
                  src={stars === 1 ? img1 : angery_face}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setStars(2);
                }}
              >
                <img
                  className="pe-auto"
                  src={stars === 2 ? img2 : sad_face}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setStars(3);
                }}
              >
                <img
                  className="pe-auto"
                  src={stars === 3 ? img3 : serious_face}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setStars(4);
                }}
              >
                <img
                  className="pe-auto"
                  src={stars === 4 ? img4 : smiling_face}
                  alt=""
                />
              </div>
              <div
                onClick={() => {
                  setStars(5);
                }}
              >
                <img
                  className="pe-auto"
                  src={stars === 5 ? img5 : big_smile_face}
                  alt=""
                />
              </div>
            </div>
          </div>
          <p class=" semibold">Send us a message how can we improve?</p>
          <div>
            <textarea
              rows={4}
              onChange={(e) => {
                setFeedBack(e.target.value);
              }}
              class="form-control text-dark border-0 p-3 small"
              placeholder="Enter your message here..."
            ></textarea>
          </div>
          <button
            type="button"
            onClick={handleSendFeedback}
            disabled={!feedBack.length}
            class="btn rounded-5 px-5 mt-3 medium btn-primary"
          >
            SUBMIT FEEDBACK
          </button>
        </div>
      </div>
    </div>
  );
};
